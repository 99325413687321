<script>
export default {
  data() {
    return {
      sessionProfiles: [],
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  methods: {
    updateProfiles(profiles) {
      this.sessionProfiles = profiles;
    },
    currentSpeaker(list) {
      this.sessionProfiles.forEach((p) => {
        p.profile.speaker = false;
        list.forEach((l) => {
          if(p.profile.id === l) {
            p.profile.speaker = true;
          }
        });
      });
    }
  }
};
</script>

<template>
  <div class="card shadow-none" v-if="this.sessionProfiles.length > 0">
    <div class="card-body ps-lg-0 pe-lg-0 pb-0">
      <ul class="list-unstyled vstack gap-3">

        <li v-for="data of sessionProfiles" :key="data.profile.id">
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <router-link :to="{ name: 'profile', params: { login: data.profile.login } }" target="_blank">
                <img :src="data.profile.urlAvatar" alt="" class="avatar-xs rounded-circle">
              </router-link>
            </div>
            <div class="flex-grow-1 ms-2">
              <h6 class="mb-1">
                <router-link :to="{ name: 'profile', params: { login: data.profile.login } }" class="text-body" target="_blank">
                  {{ data.profile.login }} &nbsp;<span class="badge bg-primary me-2" v-if="data.isModerator">moderator</span>
                </router-link>
                <div class="loader-speaking" height="12" v-if="data.profile.speaker" data-aos="zoom-in"></div>
                <i class="ri-mic-off-fill live-profile-small-icon" v-if="!data.micState"></i>
              </h6>
              <p class="text-muted mb-0">{{ data.profile.displayName }}</p>
            </div>
            <div class="flex-shrink-0">
              <div class="dropdown">
                <button class="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="ri-more-fill"></i></button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link :to="{ name: 'profile', params: { login: data.profile.login } }" class="dropdown-item" target="_blank">
                      <i class="ri-eye-fill text-muted me-2 align-bottom"></i>View
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</template>
