<script>
import { write } from 'clipboardy';
import { SimpleBar } from "simplebar-vue3";
import { nextTick } from 'vue'
import { linkify } from "./js/from-server";

export default {
  emits: ['profileClick'],
  data() {
    return {
      chatMessagesData: [],
      pinned: null,
    };
  },
  props: {
    imModerator: Boolean,
  },
  components: {
    SimpleBar
  },
  mounted() {
  },
  methods: {
    async addMessage(message, attach = false, scroll = true) {
      console.log("New message", message);
      if (message !== '') {
        this.chatMessagesData.push(message);
        await nextTick();
        if (attach)
          this.attachCopyLinkHandler();
        if (scroll)
          this.scrollToEnd();
      }
    },
    getMessageClass(message) {
      if (message.system === true)
        return '';
      if (message.isGuest)
        return 'opacity-50';
      if (message.isDonation)
        return 'bg-warning';
      return 'bg-primary';
    },
    attachCopyLinkHandler() {
      const linkElement = document.querySelectorAll('a[data-link]');
      if (linkElement && linkElement.length > 0) {
        var vm = this;

        linkElement.forEach(function (currentValue) {
          currentValue.addEventListener('click', function () {
            vm.copyToClipboard(currentValue.dataset.link);
          });
        });

      }
    },
    copyToClipboard(message) {
      console.log('copy to clipboard:', message);
      try {
        write(message);
      }
      catch (e) {
        console.error(e);
      }
    },
    scrollToEnd: function () {
      if (this.$refs.pnlMessages.lastElementChild) {
        this.$refs.pnlMessages.lastElementChild.scrollIntoView();
      }
    },
    profileAction(id, status) {
      if (confirm('Are you sure?')) {
        this.$emit('profileClick', id, status);
      }
    },
    getProfileId: function () {
      return localStorage.getItem('user.id');
    },
    getLinkifiedMessage() {
      return linkify(this.pinned.message.value);
    }
  },
};
</script>

<template>
  <SimpleBar v-if="true"></SimpleBar>
  <div class="position-relative">

    <div class="card" v-if="pinned !== null">
      <div class="card-body">
        <h5 class="card-title mb-1">
          <i class="ri-pushpin-line"></i>
          <button type="button" @click="profileAction('', 'unpin')" class="btn-close float-end fs-11" v-if="imModerator"></button>
        </h5>
        <p class="card-text" v-html="getLinkifiedMessage()"></p>
      </div>
    </div>

    <div class="chat-conversation p-3 p-lg-4 overflow-auto" data-simplebar ref="current">

      <ul ref="pnlMessages" data-simplebar="init" class="list-unstyled chat-conversation-list overflow-auto">
        <li v-for="data of chatMessagesData" :key="data.message"
          :class="{ right: `${data.align}` === 'right', left: `${data.align}` !== 'right', }" class="chat-list">
          <div class="conversation-list">
            <div class="chat-avatar" v-if="data.image && data.login">
              <router-link :to="{ name: 'profile', params: { login: data.login } }" target="_blank">
                <img :src="data.image" class="align-baseline" alt="" />
              </router-link>
            </div>
            <div class="user-chat-content">
              <div class="ctext-wrap mb-0">
                <div class="ctext-wrap-content bg-opacity-50" :class="getMessageClass(data)">
                  <p class="mb-0 ctext-content" v-html="data.message"></p>
                </div>
                <div class="dropdown message-box-drop" v-if="!data.system && !data.isDonation">
                  <a class="dropdown-toggle" href="#" target="_self" role="button" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </a>
                  <div class="dropdown-menu">
                    <router-link :to="{ name: 'profile', params: { login: data.login } }" class="dropdown-item"
                      target="_blank">
                      <i class="ri-user-line me-2 text-muted align-bottom"></i> Profile
                    </router-link>
                    <a @click="profileAction(data.profileId, 'invite')" href="#" class="dropdown-item"
                      v-if="imModerator && getProfileId() !== data.profileId">
                      <i class="ri-user-add-line me-2 text-muted align-bottom"></i> Invite
                    </a>
                    <a @click="profileAction(data.id, 'pin')" href="#" class="dropdown-item" v-if="imModerator">
                      <i class="ri-pushpin-line me-2 text-muted align-bottom"></i> Pin
                    </a>
                    <a @click="profileAction(data.profileId, 'ban')" href="#" class="dropdown-item"
                      v-if="imModerator && getProfileId() !== data.profileId">
                      <i class="ri-forbid-line me-2 text-muted align-bottom"></i> Ban
                    </a>
                  </div>
                </div>
                <div class="conversation-name ps-2 pe-2">
                  <small class="text-muted time">{{ data.time }}</small>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

    </div>
  </div>
</template>
