<script>
export default {
  emits: ["sendMessage"],
  data() {
    return {
      connected: false,
      form: {
        message: "",
      },
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  methods: {
    formSubmit() { 
      if(this.form.message !== '') {
        this.$emit('sendMessage', this.form);
        this.form.message = '';
        this.$refs.txtMessage.focus();
      }
    }
  },
  computed: {
    getProfileId: function() {
      return localStorage.getItem('user.id');
    },
  }
};
</script>

<template>
  <div class="chat-input-section pt-3" v-if="getProfileId">
    <form @submit.prevent="formSubmit">
      <div class="row g-1 align-items-center">
        <div class="col">
          <input ref="txtMessage" type="text" v-model="form.message" :disabled="!connected" class="form-control chat-input bg-light border-light" maxlength="256" :class="{ disabled: !this.connected }" placeholder="Enter Message..." />
        </div>
        <div class="col-auto ms-2">
          <b-button-group role="group" aria-label="Basic example">
            <button type="submit" class="btn btn-primary chat-send" :disabled="!connected" :class="{ disabled: !this.connected }">
              <i class="ri-send-plane-2-line"></i>
            </button>
          </b-button-group>
        </div>
      </div>
    </form>
  </div>
  <div class="chat-input-section pt-3" v-if="!getProfileId">
    <small>Only authorized users can post messages in the chat: 
    <router-link class="text-primary" to="/login">
      login
    </router-link>
    .</small>
  </div>
</template>
